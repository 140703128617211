@font-face {
    font-family: 'si';
    src: url('/assets/fonts/socicon/socicon.eot');
    src: url('/assets/fonts/socicon/socicon.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/socicon/socicon.woff') format('woff'),
         url('/assets/fonts/socicon/socicon.ttf') format('truetype'),
         url('/assets/fonts/socicon/socicon.svg#icomoonregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family:si;
        src: url(/assets/fonts/socicon/socicon.svg) format(svg);
    }
}

.soc {
    font-family:si!important;
    font-style:normal;
    font-weight:400;
    font-size: 11px;
    color: #ffffff;
    background-color: none;

    &.soc-twitter {
        background-color: #4da7de;
    }
    &.soc-twitter:before {
        content:'\e040';
    }
    &.soc-facebook {
        background-color: #3e5b98;
    }
    &.soc-facebook:before {
        content:'\e041';
    }
    &.soc-googleplus {
        background-color: #dd4b39;
    }
    &.soc-googleplus:before {
        content:'\e042';
    }
    &.soc-pinterest {
        background-color: #c92619;
    }
    &.soc-pinterest:before {
        content:'\e043';
    }
    &.soc-linkedin {
        background-color: #3371b7;
    }
    &.soc-linkedin:before {
        content:'\e049';
    }
    &.soc-rss {
        background-color: #f26109;
    }
    &.soc-rss:before {
        content:'\e00b';
    }
}
