$navbar-bg: rgba(230, 223, 208, 0.8);
$border-color: rgba(0,0,0,.8);

@font-face {
    font-family: 'Rubik-Black';
    src: url('/assets/fonts/rubik/Rubik-Black.eot');
    src: url('/assets/fonts/rubik/Rubik-Black.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/rubik/Rubik-Black.woff2') format('woff2'),
        url('/assets/fonts/rubik/Rubik-Black.woff') format('woff'),
        url('/assets/fonts/rubik/Rubik-Black.ttf') format('truetype'),
        url('/assets/fonts/rubik/Rubik-Black.svg#Rubik-Black') format('svg');
    font-style: normal;
    font-display: swap;
}

body {
    background-image: url('../assets/images/branding/paper-bg.jpg');
    color: $border-color;
}
.container.bordered {
    border: 2px solid $border-color;
    padding: 50px;
    max-width: 2000px;
    position: relative;
    margin-top: 36px;
}
a, a:hover, a:visited {
    color: $border-color;
    text-decoration: none;
    &.underline {
        text-decoration: underline;
        &.pointer {
            cursor: pointer;
        }
    }
}

$h1-font-size: $font-size-base * 1.9 !default;
$h2-font-size: $font-size-base * 1.7 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.3 !default;
$h5-font-size: $font-size-base * 1.15 !default;
$h6-font-size: $font-size-base !default;

@media (max-width: $screen-sm) {
    h1, .h1 { font-size: $h1-font-size; }
    h2, .h2 { font-size: $h2-font-size; }
    h3, .h3 { font-size: $h3-font-size; }
    h4, .h4 { font-size: $h4-font-size; }
    h5, .h5 { font-size: $h5-font-size; }
    h6, .h6 { font-size: $h6-font-size; }
}

h1 {
    font-weight: 800;
    text-transform: uppercase;
    &.logo-text {
        font-family: "Rubik-Black", helvetica;
    }
}
h2,h3,h4,h5,h6 {
    font-weight: 600;
    text-transform: uppercase;
    &.thin {
        font-weight: 400;
    }
}

hr {
    border-top: 1px solid #a5a29c;
}

.mb-0 {
  margin-bottom: 0px;
}

.mt-5 {
  margin-top: 5px;
}

.sears-logo {
    width: 70px;
    height: auto;
}
.allstate-logo {
    width: 90px;
    height: auto;
}
.homepage-logo {
    width: 350px;
    height: auto;
}
.model-plate {
    width: 150px;
    height: auto;
    margin-bottom: 5px;
    &.puch {
        width: 100px;
    }
}
@media (max-width: $screen-sm) {
   .homepage-logo {
        width: 250px;
        height: auto;
    } 
}

.border {
    border: 2px solid $border-color;
}

fieldset {
    margin: auto;
    width: 88%;
    border: 2px solid $border-color;
    padding: 10px 17px;
    font-weight: 400;
    @include media-query($on-palm) {
        width: 100%;
    }
    .column-headers {
       font-weight: 600;
    }
}

.navbar-default {
    background-color: $navbar-bg;
    text-transform: uppercase;
    img{
        width: 30px;
        height: auto;
    }
}

legend {
    width: 230px;
    text-align: center;
    padding: 0.2em 0.8em;
    border: 0;
    margin-bottom: 0;
}
.glyphicon {
    color: $border-color;
}

.modal-content {
    padding: 15px;
}

.btn-primary, .btn-primary:hover{
    background-color: #616161;
    border-color: #0c0c0c;
}

.top-footer {
    padding-top: 30px;
    padding-bottom: 20px;
    color: #373634;
    background: $navbar-bg;
    img {
        width: 100%;
        height: auto;
    }
    .logo-box {
        max-width: 150px;
    }
    .logo-text {
        font-family: "Rubik-Black", helvetica;
        text-transform: uppercase;
        font-size: .7em;
    }
}

.post-content {
    .image-section {
        img {
            width: 400px;
            height: auto;
        }
    }
}

.modal-md {
    img {
        max-width: 100px;
        height: auto;
    }
}

.btn-facebook {
    color: #fff;
    background-color: #4C67A1;
    &:hover {
        color: #fff;
        background-color: #405D9B;
    }
    &::focus {
        color: #fff;
    }
}

.label {
    white-space: inherit;
}

.hide-article {
    display: none;
}

.view-button {
    margin: 3px 0;
}


.image-square {
    position: relative;
    width: 100%; /* The size you want */

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
    }

    img {
        position: absolute; /* Take your picture out of the flow */
        top: 0;
        bottom: 0;
        left: 0;
        right: 0; /* Make the picture taking the size of it's parent */
        width: 100%; /* This if for the object-fit */
        height: 100%; /* This if for the object-fit */
        object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
        object-position: center;
    }
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: $navbar-bg;
}
@media screen and (max-width: 768px) {
  // this media query wrapper was added (not from original code snippet)
  .table-responsive-stack {
    tr {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    td, th {
      display: block;

      /*      
         flex-grow | flex-shrink | flex-basis   */
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
    }

    /*.table-responsive-stack-thead {
      font-weight: bold;
    }*/
  }
}

@media screen and (max-width: 768px) {
  .table-responsive-stack {
    tr {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      border-bottom: 1px solid #ccc;
      display: block;
    }

    td {
      float: left\9;
      width: 100%;
    }
  }

  /*  IE9 FIX   */

  .mobile-uppercase {
    text-transform: uppercase;
  }
}


// flickity start
ul.carousel {
  margin: 0;
}

.carousel-cell {
  width: 28%;
  height: auto;
  margin-right: 20px;
  counter-increment: carousel-cell;
}

@media screen and (max-width: 768px) {
  .carousel-cell {
    width: 56%;
  }
}

ul.carousel li {
  list-style: none;
}

.flickity-button:disabled {
  display: none;
}

.flickity-button {
  background: $navbar-bg;
}

.flickity-prev-next-button {
  width: 35px;
  height: 35px;
}

.flickity-prev-next-button.next {
  right: -40px;
}

.flickity-prev-next-button.previous {
  left: -40px;
}
// flickity end